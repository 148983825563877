import { render, staticRenderFns } from "./singleAdministrationChildrenSecond.vue?vue&type=template&id=1d1c361f&scoped=true"
import script from "./singleAdministrationChildrenSecond.vue?vue&type=script&lang=js"
export * from "./singleAdministrationChildrenSecond.vue?vue&type=script&lang=js"
import style0 from "./singleAdministrationChildrenSecond.vue?vue&type=style&index=0&id=1d1c361f&prod&lang=less&scope=true"
import style1 from "./singleAdministrationChildrenSecond.vue?vue&type=style&index=1&id=1d1c361f&prod&lang=less"
import style2 from "./singleAdministrationChildrenSecond.vue?vue&type=style&index=2&id=1d1c361f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d1c361f",
  null
  
)

export default component.exports