<template>
  <!-- 重构 - 结算管理 - 结算单管理 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">结算单管理</a>
          <!--运营-->
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" @tab-click="tabsChange"
          ><el-tab-pane label="收款结算单" name="first">
            <singleAdministrationChildrenFirst :name="activeName" ref="first" />
          </el-tab-pane>
          <el-tab-pane label="付款结算单" name="second">
            <singleAdministrationChildrenSecond :name="activeName" ref="second" />
          </el-tab-pane>
          <el-tab-pane label="代理结算单" name="three">
            <singleAdministrationChildrenThree :name="activeName" ref="three" />
          </el-tab-pane>
          <el-tab-pane label="代理结算单(普惠)" name="four">
            <singleAdministrationChildrenFour :name="activeName" ref="four" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import singleAdministrationChildrenFirst from "@/views/financialSettlement/settlement/singleAdministrationChildrenFirst";
import singleAdministrationChildrenSecond from "@/views/financialSettlement/settlement/singleAdministrationChildrenSecond";
import singleAdministrationChildrenThree from "@/views/financialSettlement/settlement/singleAdministrationChildrenThree";
import singleAdministrationChildrenFour from "@/views/financialSettlement/settlement/singleAdministrationChildrenFour";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "singleAdministration",
  components: {
    singleAdministrationChildrenFirst,
    singleAdministrationChildrenSecond,
    singleAdministrationChildrenThree,
    singleAdministrationChildrenFour,
  },
  mixins: [List],
  data() {
    return {
      activeName: "",
    };
  },
  created() {
    this.activeName = this.$route.query.active || "first";
  },
  mounted() {},
  methods: {
    tabsChange(e) {
      this.activeName = e.name;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: function (route) {
      if (route.query.refresh) {
        this.$refs[route.query.active].getData(-1);
      }
    },
  },
};
</script>
<style lang="less">
.upload-btn {
  width: 5rem;
  .el-upload {
    width: 5rem;
    height: auto !important;
    border: none !important;
  }
}
.upload-btndrag {
  width: 15rem;
  .el-upload {
      width: 15rem;
  }
}
</style>
