<template>
  <!-- 重构 - 结算管理 - 结算单管理 - 付款结算单 -->
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox">
        <div title="代理商" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">代理商:</span>
           <el-select
            size="small"
            v-model="retrievalDatas.agencyId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in companyList"
              :key="item.agencyId"
              :label="item.agencyName"
              :value="item.agencyId"
            ></el-option>
          </el-select>
        </div>
        <div title="结算单状态" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">结算单状态:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.auditType"
            placeholder="请选择结算单状态"
            clearable
          >
            <el-option
              v-for="item in statementList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div title="付款日期" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">付款日期:</span>
          <el-date-picker
            clearable
            size="small"
            v-model="retrievalDatas.startTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          round
          @click="getData()"
          >查询</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          round
          @click="getExport()"
          >导出</el-button
        >
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            fixed
            width="100px"
          />
          <el-table-column
            label="结算单编号"
            align="left"
            show-overflow-tooltip
            prop="billNo"
            width="200"
          />
          <el-table-column
            label="代理商名称"
            align="left"
            show-overflow-tooltip
            prop="agencyName"
            width="300"
          />
          <el-table-column
            label="应付金额"
            align="left"
            show-overflow-tooltip
            prop="payerAmount"
          />
          <el-table-column
            label="实付金额"
            align="left"
            show-overflow-tooltip
            prop="settlementAmount"
          />
          <el-table-column
            label="结算人数"
            align="left"
            show-overflow-tooltip
            width="120"
            prop="settlementUserCount"
          />
          <el-table-column
            label="付款日期"
            align="left"
            show-overflow-tooltip
            width="200"
          >
            <template slot-scope="scope">{{
              scope.row.payeeDate | momentDate
            }}</template>
          </el-table-column>

          <el-table-column
            label="结算单状态"
            align="center"
            show-overflow-tooltip
            prop="auditType"
            min-width="250px"
            ><template slot-scope="scope">
              <span>{{
                $setDictionary("FD_AUDIT_TYPE", scope.row.auditType) == "已到账"
                  ? "已付款"
                  : $setDictionary("FD_AUDIT_TYPE", scope.row.auditType)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="创建日期"
            align="left"
            show-overflow-tooltip
            width="200"
          >
            <template slot-scope="scope">{{
              scope.row.createTime | moment
            }}</template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="320px"
            show-overflow-tooltip
            align="center"
            fixed="right"
            ><template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="preview(scope.row.billId)"
                >预览
              </el-button>
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="Number(scope.row.auditType) >= 70"
                @click="editData(scope.row)"
                >编辑
              </el-button>
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="
                  scope.row.auditType != '10' && scope.row.auditType != '30'
                "
                @click="financialSubmission(scope.row.billId)"
                >提交财务
              </el-button>
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="scope.row.auditType != '40'"
                @click="sendCustomer(scope.row.billId)"
                >发送客户
              </el-button>
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="scope.row.auditType == '90'"
                @click="toVoid(scope.row.billId)"
                >作废
              </el-button>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <!-- 预览 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding"
      width="50%"
      top="2%"
      center
    >
      <div style="height: 580px" class="ovy-a">
        <div id="pdf-cert1" style="height: 600px"></div>
        <!-- <h3 style="margin: 20px 0">课程明细</h3>
      <div id="pdf-certCpurse1" style="height: 600px"></div> -->
        <div class="el-div"></div>
        <h3 style="margin: 20px 0">流转记录</h3>
        <el-table
          :data="previewData.circulationRecordData"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          class="circulationRecord"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            width="100px"
          />
          <el-table-column
            label="操作时间"
            align="left"
            show-overflow-tooltip
            min-width="150"
          >
            <template slot-scope="scope">{{
              scope.row.createTime | moment
            }}</template>
          </el-table-column>
          <el-table-column
            label="操作人"
            align="left"
            show-overflow-tooltip
            prop="fullname"
            min-width="100"
          />
          <el-table-column
            label="操作内容"
            align="left"
            show-overflow-tooltip
            min-width="100"
          >
            <template slot-scope="scope">
              {{
                $setDictionary(
                  "FD_NEW_AUDIT_TYPE_RECORD",
                  scope.row.auditType
                ) == "确认收款"
                  ? "确认付款"
                  : $setDictionary(
                      "FD_NEW_AUDIT_TYPE_RECORD",
                      scope.row.auditType
                    )
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="备注"
            align="left"
            show-overflow-tooltip
            prop="remark"
            min-width="150"
          />
           <el-table-column
            label="操作"
            align="left"
            show-overflow-tooltip
            min-width="150"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="!scope.row.hasFile"
                @click="preview2(scope.row)"
                >预览附件
              </el-button>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>

    <!-- 预览附件 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      @close="uploadLodClose"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert2"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>

    <!-- 发送客户 - loading -->
    <el-dialog
      title="发送客户"
      :visible.sync="sendCustomerLoding"
      width="30%"
      class="sendCustomerLoding"
      @close="close"
      ><i class="el-icon-success"></i>
      <p>已成功发送至客户</p>
      <el-button
        type="primary"
        size="small"
        class="bgc-bv"
        round
        @click="downloadStatement"
        >下载结算单</el-button
      >
    </el-dialog>
    <!-- 作废 - loading -->
    <el-dialog
      title="作废结算单"
      :visible.sync="toVoidLoding"
      width="30%"
      class="toVoidLoding"
    >
      <p class="p1">确定作废该结算单吗？</p>
      <p class="p2">结算单作废后，可重新生成。</p>
      <p class="p2">原来的结算单号作废，重新生成新的结算单号。</p>
      <div style="margin-bottom: 15px">
        <el-input
          type="textarea"
          placeholder="请输入作废原因"
          v-model="remark"
          maxlength="255"
          show-word-limit
        >
        </el-input>
      </div>
      <div style="margin: 10 0 10px">
        <el-upload
          class="upload-demo upload-btn"
          :action="actionUrl"
          :on-error="handleError"
          :on-success="handleSuccess"
          :on-change="uploadChange"
          multiple
          :limit="5"
          :show-file-list="false"
          :auto-upload="false"
        >
          <el-button class="bgc-bv">上传附件</el-button>
        </el-upload>
        <div v-if="NameList.length" style="margin-top: 10px; margin-left: 10px">
          当前选择文件：
          <div style="display: flex; flex-direction: column">
            <span
              style="color: #409eff"
              v-for="(item, index) in NameList"
              :key="index"
            >
              {{ item.fileName }}
              <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
              <a @click="reomveExl(index)" style="color: red; margin: 0 15px"
                >删除</a
              >
            </span>
          </div>
        </div>
        <div v-else>
          <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="toVoidDetermineClose"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="toVoidDetermine"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
export default {
  name: "singleAdministration",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      // 页面检索数据
      retrievalDatas: {
        billNo: "", //结算单编号
        auditType: "", //结算单状态
        startTime: "", //付款日期
        agencyId: "", //代理商
      },
      // 检索 - 代理商 - 下拉数据
      companyList: [],
      // 检索 - 结算单状态 - 下拉数据
      statementList: [],
      // 列表操作 - 预览 - loading
      previewLoding: false,
      // 列表操作 - 预览 - 数据
      previewData: {
        // 流转记录列表数据
        circulationRecordData: [],
      },
      // 列表操作 - 发送客户 - loading
      sendCustomerLoding: false,
      // 列表操作 - 作废 - loading
      toVoidLoding: false,
      fileName: "",
      NameList: [],
      remark: "",
      previewLoding1: false,
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getStatusList();
    if (this.name == "four") {
      this.getData();
    }
  },
  methods: {
    //代理商
    getCompanyList(query) {
      if (query.trim().length >= 2) {
      this.$post("/biz/mercantile/agency/searchList", {agencyName:query})
        .then((res) => {
          if (res.status == 0) {
            this.companyList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
      }
    },
    // 获取 - 结算单状态
    getStatusList() {
      const statusList = this.$setDictionary("FD_AUDIT_TYPE", "list");
      const list = [];
      for (const key in statusList) {
        list.push({
          value: key,
          label: statusList[key],
        });
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].value == "70") {
          list[i].label = "已付款";
        }
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].value == "60") {
          list.splice(i, 1);
        }
      }
      this.statementList = list;
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalDatas.agencyId) {
        params.agencyId = this.retrievalDatas.agencyId;
      }
      if (this.retrievalDatas.auditType) {
        params.auditType = this.retrievalDatas.auditType;
      }
      if (this.retrievalDatas.startTime) {
        params.payeeDateStart = this.retrievalDatas.startTime[0];
        params.payeeDateEnd = this.retrievalDatas.startTime[1];
      }
      this.doFetch({
        url: "/biz/new/billAgent/operation/pawPageList",
        params,
        pageNum,
      });
    },
    /* 导出 */
    getExport() {
      let params = {};
      if (this.retrievalDatas.agencyId) {
        params.agencyId = this.retrievalDatas.agencyId;
      }
      if (this.retrievalDatas.auditType) {
        params.auditType = this.retrievalDatas.auditType;
      }
      if (this.retrievalDatas.startTime) {
        params.payeeDateStart = this.retrievalDatas.startTime[0];
        params.payeeDateEnd = this.retrievalDatas.startTime[1];
      }
      this.$post("/biz/new/billAgent/operation/pawListExport", params)
        .then((res) => {
          if (res.status == "0") {
            let list = [];
            list.push(res.data);
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 预览
    preview(billId) {
      this.previewLoding = true;
      this.$post("/biz/new/billAgent/operation/review", { billId })
        .then((ret) => {
          this.$nextTick(() => {
            pdf.embed(ret.message, "#pdf-cert1");
          });
        })
        .catch((err) => {
          return;
        });
      this.getoperation(billId);
    },
    // 流转记录列表数据
    getoperation(billId) {
      this.$post("/biz/new/audit/list", { billId })
        .then((ret) => {
          this.previewData.circulationRecordData = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    // 编辑
    editData(row) {
      this.$router.push({
        path: "/web/financialSettlement/settlement/singleAdministrationChildrenFourEdit",
        query: {
          editInfo: JSON.stringify({
            id: row.billId,
            type: "four",
            addEdit: "edit",
          }),
        },
      });
    },
    // 提交财务
    financialSubmission(billId) {
      this.$post("/biz/new/billAgent/operation/submitFinancial", { billId })
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.getData();
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 发送客户
    sendCustomer(billId) {
      this.$post("/biz/new/billAgent/operation/sendCustomer", { billId })
        .then((ret) => {
          if (ret.status == "0") {
            this.sendCustomerLoding = true;
            this.sendStatement = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 发送客户 - 下载结算单
    downloadStatement() {
      window.open(this.sendStatement);
      this.close();
    },
    close() {
      this.sendCustomerLoding = false;
      this.getData();
    },
    // 作废
    toVoid(billId) {
      this.toVoidLoding = true;
      this.billId = billId;
    },
    //作废 - 取消
    toVoidDetermineClose() {
      this.toVoidLoding = false;
      this.remark = "";
      this.NameList = [];
    },
    // 作废 - 确定
    toVoidDetermine() {
      let parmar = {};
      parmar.fdNewAudit = {
        billId: this.billId,
        remark: this.remark,
      };
      parmar.fileKeys = this.NameList;
      if (this.remark == "") {
        this.$message({
          message: "请输入作废原因",
          type: "error",
        });
        return;
      }
      this.$post("/biz/new/audit/invalid", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "作废成功",
              type: "success",
            });
            this.toVoidLoding = false;
            this.remark == "";
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      // const isXLSX = extension === "XLSX";
      // const isxlsx = extension === "xlsx";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx) {
        this.$message.error("只能上传后缀是.pdf或.PDF的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.NameList.push({
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileType: extension,
          });
          // this.ruleForm.chatFileKeys.push(
          //   result.data.fileKey + "_" + file.name
          // );
          // console.log(this.ruleForm.chatFileKeys);
        })
        .catch((err) => {
          return;
        });
    },
    // 作废 - 移除文件
    reomveExl(index) {
      this.NameList.splice(index, 1);
      this.$forceUpdate();
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
     // 预览
    preview2(row) {
      this.fileType = row.fileType;
      this.$post("/biz/new/audit/auditFileView", { auditId: row.auditId })
        .then((ret) => {
          console.log(ret.message);
          this.previewLoding1 = true;
          if (row.fileType == "pdf" || row.fileType == "PDF") {
            this.$nextTick(() => {
              pdf.embed(ret.message, "#pdf-cert2");
            });
          } else {
            this.ImgSrc = ret.message;
          }
        })
        .catch((err) => {
          return;
        });
      // this.getoperation(billId);
    },
  },
  mounted: function () {},
  watch: {
    name(newVal) {
      if (newVal == "four") {
        this.getData();
      }
    },
  },
};
</script>
<style lang="less" scope>
.el-div {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid #dcdfe6;
}
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.previewData /deep/ .el-table__body-wrapper {
  min-height: 200px;
}
.circulationRecord /deep/ .el-table__body-wrapper {
  min-height: 200px;
}
.toVoidLoding /deep/ .el-dialog__footer {
  text-align: center;
}
.toVoidLoding /deep/ .el-dialog__footer .el-button {
  width: 80px;
}
.toVoidLoding /deep/ .el-dialog__body .p1 {
  text-align: center;
  font-size: 17px;
  margin: 30px 0 10px 0;
}
.toVoidLoding /deep/ .el-dialog__body .p2 {
  text-align: center;
}
</style>
<style lang="less" scoped>
.sendCustomerLoding /deep/ .el-dialog__body i {
  font-size: 55px;
  color: #67c23a;
  display: block;
  text-align: center;
  margin-top: 20px;
}
.sendCustomerLoding /deep/ .el-dialog__body p {
  text-align: center;
  font-size: 16px;
  margin: 40px 0 40px 0;
}
.sendCustomerLoding /deep/ .el-dialog__body .el-button {
  display: block;
  width: 100px;
  margin: auto;
}
</style>