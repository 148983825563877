<template>
  <!-- 重构 - 结算管理 - 结算单管理 - 付款结算单 -->
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox" style="margin-bottom: 5px">
        <div title="结算单编号" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:6rem">结算单编号:</span>
          <el-input
            v-model="retrievalDatas.billNo"
            type="text"
            size="small"
            placeholder="请输入结算单编号"
            clearable
          />
        </div>
        <div title="课程名称" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:6rem">课程名称:</span>
          <el-input
            v-model="retrievalDatas.courseName"
            type="text"
            size="small"
            placeholder="请输入课程名称"
            clearable
          />
        </div>
        <div title="课程提供方名称" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 10rem">课程提供方名称:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.compId"
            remote
            :remote-method="curriculumSquare"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in curriculumSquareData"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchbox" style="margin: 10px 0 5px">
        <div title="使用班级" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:6rem">使用班级:</span>
          <el-input
            v-model="retrievalDatas.projectName"
            type="text"
            size="small"
            placeholder="请输入使用班级"
            clearable
          />
        </div>
        <div title="结算单状态" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:6rem">结算单状态:</span>
          <el-select
            size="small"
            v-model="retrievalDatas.auditType"
            placeholder="请选择结算单状态"
            clearable
          >
            <el-option
              v-for="item in statementList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div title="是否收到课程方发票" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 10rem"
            >是否收到课程方发票:</span
          >
          <el-select
            size="small"
            v-model="retrievalDatas.isInvoice"
            placeholder="请选择是否收到课程方发票"
            clearable
          >
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchbox" style="margin: 10px 0 5px">
        <div title="付款日期" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:6rem">付款日期:</span>
          <el-date-picker
            clearable
            size="small"
            v-model="retrievalDatas.startTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>

        <div title="创建日期" class="searchboxItem ci-full">
          <span class="itemLabel">创建日期:</span>
          <el-date-picker
            clearable
            size="small"
            v-model="retrievalDatas.createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          round
          @click="getData()"
          >查询</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          round
          @click="getExport()"
          >导出</el-button
        >
      </div>
    </div>
    <div style="margin: 15px 0.675rem 5px">
      <span>合计课程费用：{{ totalCourseMoney }} 元</span>
      <span style="margin: 0 30px">合计结算人数：{{ totalSettleNum }} 人</span>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            fixed
            width="100px"
          />
          <el-table-column
            label="结算单编号"
            align="left"
            show-overflow-tooltip
            prop="billNo"
            width="200"
          />
          <el-table-column
            label="课程提供方"
            align="left"
            show-overflow-tooltip
            prop="compOwnerName"
            width="300"
          />
          <el-table-column
            label="课程名称"
            align="left"
            show-overflow-tooltip
            prop="courseName"
            width="300"
          />
          <el-table-column
            label="课程提供方课时"
            align="left"
            show-overflow-tooltip
            prop="totalLessonNum"
            width="150"
          />
          <el-table-column
            label="使用班级"
            align="left"
            show-overflow-tooltip
            prop="projectName"
            width="200"
          />
          <el-table-column
            label="课程费用"
            align="left"
            show-overflow-tooltip
            width="120"
            prop="payerAmount"
          />
          <el-table-column
            label="平台结算金额（元）"
            align="left"
            show-overflow-tooltip
            width="140"
            prop="platformSettlementAmount"
          />
          <el-table-column
            label="结算人数"
            align="left"
            show-overflow-tooltip
            width="120"
            prop="settlementUserCount"
          />
          <el-table-column
            label="付款日期"
            align="left"
            show-overflow-tooltip
            width="200"
          >
            <template slot-scope="scope">{{
              scope.row.payeeDate | momentDate
            }}</template>
          </el-table-column>
          <el-table-column
            label="是否收到课程方发票"
            align="left"
            show-overflow-tooltip
            width="150"
          >
            <template slot-scope="scope">{{
              scope.row.isInvoice ? "是" : "否"
            }}</template>
          </el-table-column>
          <el-table-column
            label="结算单状态"
            align="center"
            show-overflow-tooltip
            prop="audit_type"
            min-width="250px"
            ><template slot-scope="scope">
              <span>{{ $setDictionary("FD_AUDIT_TYPE", scope.row.auditType) == '已到账'?'已付款': $setDictionary("FD_AUDIT_TYPE", scope.row.auditType) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="创建日期"
            align="left"
            show-overflow-tooltip
            width="200"
          >
            <template slot-scope="scope">{{
              scope.row.createTime | moment
            }}</template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="320px"
            align="center"
            fixed="right"
            ><template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="preview(scope.row.billId)"
                >预览
              </el-button>
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="Number(scope.row.auditType)>=70"
                @click="editData(scope.row)"
                >编辑
              </el-button>
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="
                  scope.row.auditType != '10' && scope.row.auditType != '30'
                "
                @click="financialSubmission(scope.row.billId)"
                >提交财务
              </el-button>
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="scope.row.auditType != '40'"
                @click="sendCustomer(scope.row.billId)"
                >发送客户
              </el-button>
               <!-- <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="uploadFile(scope.row.billId)"
                >上传附加
              </el-button> -->
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="scope.row.auditType == '90'"
                @click="toVoid(scope.row.billId)"
                >作废
              </el-button>
               <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="uploadFile(scope.row.billId)"
                >上传附件
              </el-button>

              <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="listDownloadClassList(scope.row)"
              >下载班级列表
              </el-button>
              <!-- <el-popover
                placement="bottom"
                trigger="click">
                <p style="text-align: center;">下载结算单时是否带章</p>
                <div style="text-align: center;">
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="listDownloadStatement(scope.row,true)">带电子章</el-button> -->
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="listDownloadStatement(scope.row,false)">下载结算单</el-button>
                <!-- </div>
                <el-button slot="reference" size="mini" type="text">下载结算单</el-button>
              </el-popover> -->
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <!-- 预览 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding"
      width="50%"
      top="2%"
      center
      >
       <div style="height:580px" class="ovy-a">
      <div id="pdf-cert1" style="height: 600px"></div>
      <!-- <h3 style="margin: 20px 0">课程明细</h3>
      <div id="pdf-certCpurse1" style="height: 600px"></div> -->
       <div class="el-div"></div>
      <h3 style="margin: 20px 0">流转记录</h3>
      <el-table
        :data="previewData.circulationRecordData"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="tableHeader"
        class="circulationRecord"
        stripe
      >
        <el-table-column
          label="序号"
          align="center"
          type="index"
          :index="indexMethod"
          width="100px"
        />
        <el-table-column
          label="操作时间"
          align="left"
          show-overflow-tooltip
          min-width="150"
        >
          <template slot-scope="scope">{{
            scope.row.createTime | moment
          }}</template>
        </el-table-column>
        <el-table-column
          label="操作人"
          align="left"
          show-overflow-tooltip
          prop="fullname"
          min-width="100"
        />
        <el-table-column
          label="操作内容"
          align="left"
          show-overflow-tooltip
          min-width="100"
        >
          <template slot-scope="scope">
            {{ $setDictionary("FD_NEW_AUDIT_TYPE_RECORD",scope.row.auditType) =='确认收款' ?'确认付款':$setDictionary("FD_NEW_AUDIT_TYPE_RECORD",scope.row.auditType)}}
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          align="left"
          show-overflow-tooltip
          prop="remark"
          min-width="150"
        />
         <el-table-column
            label="操作"
            align="left"
            show-overflow-tooltip
            min-width="150"
          >
          <template slot-scope="scope">
            <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="!scope.row.hasFile"
                @click="preview2(scope.row)"
                >预览附件
              </el-button>
          </template>
          </el-table-column>
        <Empty slot="empty" />
      </el-table>
       </div>
    </el-dialog>
     <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
    <!-- 发送客户 - loading -->
    <el-dialog
      title="发送客户"
      :visible.sync="sendCustomerLoding"
      width="30%"
      class="sendCustomerLoding"
      @close="close"
      ><i class="el-icon-success"></i>
      <p>已成功发送至客户</p>
      <el-button
        type="primary"
        size="small"
        class="bgc-bv"
        round
        @click="downloadStatement"
        >下载结算单</el-button
      >
    </el-dialog>
    <!-- 作废 - loading -->
    <el-dialog
      title="作废结算单"
      :visible.sync="toVoidLoding"
      width="30%"
      class="toVoidLoding"
    >
      <p class="p1">确定作废该结算单吗？</p>
      <p class="p2">结算单作废后，可重新生成。</p>
      <p class="p2">原来的结算单号作废，重新生成新的结算单号。</p>
       <div style="margin-bottom:15px">
        <el-input
          type="textarea"
          placeholder="请输入作废原因"
          v-model="remark"
          maxlength="255"
          show-word-limit
        >
        </el-input>
      </div>
      <div style="margin:10 0 10px">
        <el-upload
          class="upload-demo upload-btn"
          :action="actionUrl"
          :on-error="handleError"
          :on-success="handleSuccess"
          :on-change="uploadChange"
          multiple
                    :limit="5"
          :show-file-list="false"
          :auto-upload="false"
        >
          <el-button class="bgc-bv"
            >上传附件</el-button
          >
        </el-upload>
        <div v-if="NameList.length" style="margin-top:10px;margin-left:10px">
          当前选择文件：
          <div style="display:flex;flex-direction: column;">
                  <span
                    style="color:#409EFF"
                    v-for="(item, index) in NameList"
                    :key="index"
                  >
                    {{ item.fileName }}
                    <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
                    <a
                      @click="reomveExl(index)"
                      style="color: red;margin:0 15px"
                      >删除</a
                    >
                  </span>
                </div>
        </div>
        <div v-else>
          <p  style="margin-top:10px;margin-left:10px">
                    未选择文件
                  </p>
        </div>
       
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="toVoidDetermineClose"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="toVoidDetermine"
          >确 定</el-button
        >
      </span>
    </el-dialog>
     <!-- 上传附件 - loading -->
    <el-dialog
      title="上传附件"
      :visible.sync="uploadfiledialog"
      width="30%"
      class="toVoidLoding"
    >
      <el-radio-group v-model="docType">
    <el-radio label="10">结算单照片</el-radio>
    <el-radio label="20">结算单原单</el-radio>
    <el-radio label="99">其他</el-radio>
  </el-radio-group>
      <div style="margin-bottom:15px">
        <el-input
          type="textarea"
          placeholder="请输入备注"
          v-model="fileremark"
          maxlength="500"
          show-word-limit
        >
        </el-input>
      </div>
      <div style="margin:10 0 10px">
         <el-upload
          class="upload-demo upload-btn upload-btndrag"
          :action="actionUrl"
          :on-error="handleError"
          :on-success="handleSuccess"
          :on-change="uploadChange1"
          :show-file-list="false"
          :auto-upload="false"
          drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div v-if="uploadfileList.length" style="margin-top:10px;margin-left:10px">
          当前选择文件：
          <div style="display:flex;flex-direction: column;">
                  <span
                    style="color:#409EFF"
                    v-for="(item, index) in uploadfileList"
                    :key="index"
                  >
                    {{ item.fileName }}
                    <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
                      <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px"
                >预览</a
              >
                    <a
                      @click="reomveExlUpload(index)"
                      style="color: red;margin:0 15px"
                      >删除</a
                    >
                  </span>
                </div>
        </div>
        <div v-else>
          <p  style="margin-top:10px;margin-left:10px">
                    未选择文件
                  </p>
        </div>
       
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="uploadfileClose"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="uploadfileDetermine"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
export default {
  name: "singleAdministration",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      // 页面检索数据
      retrievalDatas: {
        billNo: "",
        courseName: "",
        compId: "",
        projectName: "",
        isInvoice: "",
        auditType: "",
        startTime: "",
        createTime: "",
      },
      // 检索 - 课程提供方名称 - 下拉数据
      curriculumSquareData: [],
      // 检索 - 结算单状态 - 下拉数据
      statementList: [],
      // 列表操作 - 预览 - loading
      previewLoding: false,
      // 列表操作 - 预览 - 数据
      previewData: {
        // 流转记录列表数据
        circulationRecordData: [],
      },
      // 列表操作 - 发送客户 - loading
      sendCustomerLoding: false,
      // 列表操作 - 作废 - loading
      toVoidLoding: false,
      totalCourseMoney:0,
      totalSettleNum:0,
       fileName: "",
      NameList:[],
      remark:'',
      previewLoding1:false,
       uploadfiledialog:false,//上传附件弹框
      docType:'10',//文档类型
      fileremark:'',//上传附件备注
       uploadfileList:[], //上传附件
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getStatusList();
    if (this.name == "second") {
      this.getData();
    }
  },
  methods: {
    // 获取 - 课程提供方名称
    curriculumSquare(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.curriculumSquareData = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.curriculumSquareData = [];
      }
    },
    // 获取 - 结算单状态
    getStatusList() {
      const statusList = this.$setDictionary("FD_AUDIT_TYPE", "list");
      const list = [];
      for (const key in statusList) {
          list.push({
            value: key,
            label: statusList[key],
          });
      }
      for(let i=0; i<list.length; i++) {
        if(list[i].value == '70'){
          list[i].label = '已付款'
        }
      }
      for(let i=0; i<list.length; i++) {
        if(list[i].value == '60'){
          list.splice(i,1)
        }
      }
      this.statementList = list;
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        isInvoice: this.retrievalDatas.isInvoice,
      };
      if (this.retrievalDatas.billNo) {
        params.billNo = this.retrievalDatas.billNo;
      }
      if (this.retrievalDatas.courseName) {
        params.courseName = this.retrievalDatas.courseName;
      }
      if (this.retrievalDatas.compId) {
        params.compOwnerId = this.retrievalDatas.compId;
      }
      if (this.retrievalDatas.projectName) {
        params.projectName = this.retrievalDatas.projectName;
      }
      if (this.retrievalDatas.auditType) {
        params.auditType = this.retrievalDatas.auditType;
      }
      if (this.retrievalDatas.startTime) {
        params.payeeDateStart = this.retrievalDatas.startTime[0];
        params.payeeDateEnd = this.retrievalDatas.startTime[1];
      }
      if (this.retrievalDatas.createTime) {
        params.createDateStart = this.retrievalDatas.createTime[0];
        params.createDateEnd = this.retrievalDatas.createTime[1];
      }
      this.doFetch({
        url: "/biz/new/bill/pay/paymentStatementList",
        params,
        pageNum,
      });
      this.getCount(params);
    },
    //统计
    getCount(params) {
      this.$post("/biz/new/bill/pay/paymentStatementCount", params)
        .then((ret) => {
          if(ret.data) {
            this.totalCourseMoney = ret.data.totalCourseMoney;
          this.totalSettleNum = ret.data.totalSettleNum;
          } else {
              this.totalCourseMoney = 0;
          this.totalSettleNum = 0;
          }
          
        })
        .catch((err) => {
          return;
        });
    },
    /* 导出 */
    getExport() {
      let params = {
        isInvoice: this.retrievalDatas.isInvoice,
      };
      if (this.retrievalDatas.billNo) {
        params.billNo = this.retrievalDatas.billNo;
      }
      if (this.retrievalDatas.courseName) {
        params.courseName = this.retrievalDatas.courseName;
      }
      if (this.retrievalDatas.compOwnerId) {
        params.compOwnerId = this.retrievalDatas.compOwnerId;
      }
      if (this.retrievalDatas.projectName) {
        params.projectName = this.retrievalDatas.projectName;
      }
      if (this.retrievalDatas.auditType) {
        params.auditType = this.retrievalDatas.auditType;
      }
      if (this.retrievalDatas.startTime) {
        params.payeeDateStart = this.retrievalDatas.startTime[0];
        params.payeeDateEnd = this.retrievalDatas.startTime[1];
      }
      if (this.retrievalDatas.createTime) {
        params.createDateStart = this.retrievalDatas.createTime[0];
        params.createDateEnd = this.retrievalDatas.createTime[1];
      }
      this.$post("/biz/new/bill/pay/exportPaymentStatement", params)
        .then((res) => {
          if (res.status == "0") {
            let list = [];
            list.push(res.data);
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 预览
    preview(billId) {
      this.previewLoding = true;
      this.$post("/biz/new/bill/pay/review", { billId ,isCa:false})
        .then((ret) => {
          this.$nextTick(() => {
            pdf.embed(ret.data, "#pdf-cert1");
          });
        })
        .catch((err) => {
          return;
        });
      // this.getCourseList(billId);
      this.getoperation(billId);
    },
    // 下载班级列表
    listDownloadClassList(row){
      this.$post("/biz/new/bill/pay/downloadProjectDetail", { billId: row.billId })
          .then((ret) => {
            if (ret.status == "0") {
              console.log(ret.data)
              let list = ret.data[0];
              if (!this.downloadItems.includes(list.taskId)) {
                this.$store.dispatch("pushDownloadItems", list.taskId);
              } else {
                this.$message.warning(
                    "[" + list.fileName + "]已经申请下载,请耐心等待"
                );
              }
              this.closeDocsDialog();
            } else {
              this.$message.error(ret.message);
            }
            console.log(ret)
          })
          .catch((err) => {
            return;
          });
    },

    // 下载结算单
    listDownloadStatement(row,isCa) {
      // this.previewLoding = true;
      this.$post("/biz/new/bill/pay/review", { billId: row.billId,isCa })
          .then((ret) => {
            this.$nextTick(() => {
              this.download(ret.data,row.compOwnerName+'_'+row.billNo+'_'+this.getNowFormatDate()+'.pdf')
            });
          })
          .catch((err) => {
            return;
          });
      // this.getoperation(billId);
    },
    download(url, filename) {
      this.getBlob(url,  (blob)=> {
        this.saveAs(blob, filename);
      });
    },
    getBlob(url, cb) {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response);
        }
      };
      xhr.send();
    },
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement('a');
        let body = document.querySelector('body');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        // fix Firefox
        link.style.display = 'none';
        body.appendChild(link);
        link.click();
        body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      };

    },
    // 获取当前日期
    getNowFormatDate() {
      let date = new Date(),
          seperator1 = '-', //格式分隔符
          year = date.getFullYear(), //获取完整的年份(4位)
          month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
          strDate = date.getDate() // 获取当前日(1-31)
      if (month >= 1 && month <= 9) month = '0' + month // 如果月份是个位数，在前面补0
      if (strDate >= 0 && strDate <= 9) strDate = '0' + strDate // 如果日是个位数，在前面补0

      let currentdate = year + seperator1 + month + seperator1 + strDate
      return currentdate
    },

    // 预览
    preview2(row) {
       this.previewLoding1 = true;
      this.fileType = row.fileType;
      this.$post("/biz/new/audit/auditFileView", { auditId: row.auditId })
        .then((ret) => {
          if (row.fileType == "pdf" || row.fileType == "PDF") {
            this.$nextTick(() => {
              pdf.embed(ret.message, "#pdf-cert2");
            });
          } else {
            this.ImgSrc = ret.message;
          }
        })
        .catch((err) => {
          return;
        });
      // this.$post("/biz/new/audit/auditFileView", { auditId })
      //   .then((ret) => {
      //     this.$nextTick(() => {
      //       pdf.embed(ret.message, "#pdf-cert2");
      //     });
      //   })
      //   .catch((err) => {
      //     return;
      //   });
    },
    // 课程明细
    // getCourseList(billId) {
    //   this.$post("/biz/new/bill/pay/previewCourseDetailsByBill", { billId })
    //     .then((ret) => {
    //       this.$nextTick(() => {
    //         pdf.embed(ret.data, "#pdf-certCpurse1");
    //       });
    //     })
    //     .catch((err) => {
    //       return;
    //     });
    // },
    getoperation(billId) {
      this.$post("/biz/new/audit/list", { billId })
        .then((ret) => {
          this.previewData.circulationRecordData = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    // 编辑
    editData(row) {
      this.$router.push({
        path:
          "/web/financialSettlement/settlement/singleAdministrationChildrenSecondEdit",
        query: {
          editInfo: JSON.stringify({
            id: row.billId,
            type: "second",
            addEdit: "edit",
          }),
        },
      });
    },
    // 提交财务
    financialSubmission(billId) {
      this.$post("/biz/new/audit/submitFinancial", { billId })
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.getData();
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 发送客户
    sendCustomer(billId) {
      this.$post("/biz/new/audit/sendCustomer", { billId })
        .then((ret) => {
          if (ret.status == "0") {
            this.sendCustomerLoding = true;
            this.sendStatement = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 发送客户 - 下载结算单
    downloadStatement() {
      window.open(this.sendStatement);
      this.close();
    },
    close() {
      this.sendCustomerLoding = false;
      this.getData();
    },
    // 作废
    toVoid(billId) {
      this.toVoidLoding = true;
      this.billId = billId;
    },
     //作废 - 取消
    toVoidDetermineClose() {
this.toVoidLoding = false;
this.remark = '';
this.NameList = []
    },
     // 作废 - 确定
    toVoidDetermine() {
      let parmar  ={}
       parmar.fdNewAudit ={
          billId:this.billId,
          remark:this.remark
        }
       parmar.fileKeys = this.NameList
       if(this.remark == '') {
         this.$message({
           message:'请输入作废原因',
           type:'error'
         })
         return
       }
      this.$post("/biz/new/audit/invalid", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "作废成功",
              type: "success",
            });
            this.toVoidLoding = false;
            this.remark == ''
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
         const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      // const isXLSX = extension === "XLSX";
      // const isxlsx = extension === "xlsx";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx ) {
        this.$message.error("只能上传后缀是.pdf或.PDF的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
            this.NameList.push({
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileType:extension
          });
          // this.ruleForm.chatFileKeys.push(
          //   result.data.fileKey + "_" + file.name
          // );
          // console.log(this.ruleForm.chatFileKeys);
        })
        .catch((err) => {
          return;
        });
    },
    //作废上传移除
      reomveExl(index) {
      this.NameList.splice(index, 1);
      this.$forceUpdate();
    },
     //上传附件
    uploadFile(billId) {
      this.uploadfiledialog = true;
      this.billId = billId;
      this.getfileList(billId)
    },
     uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.uploadfileList.push({
            billId: this.billId,
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileType: extension,
            fileUrl: result.data.fileURL,
          });
        })
        .catch((err) => {
          return;
        });
    },
    uploadfileClose() {
        this.uploadfiledialog = false;
            this.fileremark == '';
            this.uploadfileList = [];
    },
     // 上传附件 - 确定
    uploadfileDetermine() {
        let parmar = {
        billId: this.billId,
        remark: this.fileremark,
        docType: this.docType,
      };
      parmar.fileList = this.uploadfileList;
       if(this.fileremark == '') {
         this.$message({
           message:'请输入备注',
           type:'error'
         })
         return
       }
      this.$post("/biz/new/bill/saveFiles", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.uploadfiledialog = false;
            this.fileremark == '';
            this.uploadfileList = [];
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
      // 上传附件 - 移除
     reomveExlUpload(index) {
      this.uploadfileList.splice(index, 1);
      this.$forceUpdate();
    },
    lookExl(item) {
      console.log(item.fileUrl);
      this.previewLoding1 = true;
      this.fileType = item.fileType;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
    getfileList(billId) {
      this.$post("/biz/new/bill/billFileList", { billId })
        .then((ret) => {
          this.uploadfileList = ret.data.fdNewBillFileBOList || [];
          this.fileremark = ret.data.remark || "";
          console.log(this.fileremark);
        })
        .catch((err) => {
          return;
        });
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 1 + 3 + 2.5 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 80;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function () {},
  watch: {
    name(newVal) {
      if (newVal == "second") {
        this.getData();
      }
    },
  },
};
</script>
<style lang="less" scope>
.el-div {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid #dcdfe6;
}
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.previewData /deep/ .el-table__body-wrapper {
  min-height: 200px;
}
.circulationRecord /deep/ .el-table__body-wrapper {
  min-height: 200px;
}

.toVoidLoding /deep/ .el-dialog__footer {
  text-align: center;
}
.toVoidLoding /deep/ .el-dialog__footer .el-button {
  width: 80px;
}
.toVoidLoding /deep/ .el-dialog__body .p1 {
  text-align: center;
  font-size: 17px;
  margin: 30px 0 10px 0;
}
.toVoidLoding /deep/ .el-dialog__body .p2 {
  text-align: center;
}
</style>
<style lang="less" scoped>
.sendCustomerLoding /deep/ .el-dialog__body i {
  font-size: 55px;
  color: #67c23a;
  display: block;
  text-align: center;
  margin-top: 20px;
}
.sendCustomerLoding /deep/ .el-dialog__body p {
  text-align: center;
  font-size: 16px;
  margin: 40px 0 40px 0;
}
.sendCustomerLoding /deep/ .el-dialog__body .el-button {
  display: block;
  width: 100px;
  margin: auto;
}
</style>