import { render, staticRenderFns } from "./singleAdministrationChildrenFour.vue?vue&type=template&id=4c8d335e&scoped=true"
import script from "./singleAdministrationChildrenFour.vue?vue&type=script&lang=js"
export * from "./singleAdministrationChildrenFour.vue?vue&type=script&lang=js"
import style0 from "./singleAdministrationChildrenFour.vue?vue&type=style&index=0&id=4c8d335e&prod&lang=less&scope=true"
import style1 from "./singleAdministrationChildrenFour.vue?vue&type=style&index=1&id=4c8d335e&prod&lang=less"
import style2 from "./singleAdministrationChildrenFour.vue?vue&type=style&index=2&id=4c8d335e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c8d335e",
  null
  
)

export default component.exports